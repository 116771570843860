/**
 * Stellt eine Bewertungsskala zur Verfügung.
 * Auf einem Input-Element aktivieren: $('#rating').rating( [options] );
 * Achtung: bei Verwendung mehrerer Ratings, müssen auf den input Feldern IDs vorhanden sein!
 */
(($) => {
	$.fn.rating = function (options) {
		const $hRating = this;

		// Die Settings können über die Options überschrieben werden
		const settings = $.extend(
			{
				// Font-Awesome-Icon, wenn Icon aktiv
				iconActive: "fa-star",
				// Font-Awesome-Icon, wenn Icon nicht aktiv
				iconInactive: "fa-star-o",
				// Klassen-Attribut, wenn Icon aktiv
				iconActiveClassAttr: "rating-star",
				// Klassen-Attribut, wenn Icon nicht aktiv
				iconInactiveClassAttr: "rating-star",
				// Größe des Icons(Font-Awesome-Größen-Klasse)
				iconSize: "fa-2x",
				// Erster Wert, der ausgewählt werden kann
				// ( z.B. 3 -> Rating beginnt mit 3 und zähl hoch bis X)
				begin: 1,
				// Letzter Wert(Der letzte auswählbare Wert), Differenz zwischen begin und end
				// ergibt die Anzahl an selektierbaren Ratings
				end: 5,
			},
			options,
		);

		// Wenn mehrere Objekte übergeben wurden, dann für jedes einmal rating aufrufen
		if ($hRating.length > 1) {
			$.each($hRating, function () {
				$(this).rating(settings);
			});
			return true;
		}

		$hRating.addClass("no-display");

		const id = `${$hRating.attr("id")}_rating`;

		$hRating.after(generateRatingDiv(id, settings));

		const $ratingDiv = $(`#${id}`);

		// Rating visuell darstellen
		showRating($hRating.val());

		// Funktionen zum verändern des Ratings nur aktivieren, wenn das input nicht disabled ist
		if (!$hRating.prop("disabled")) {
			$ratingDiv.delegate(".rating_icon", "click", function () {
				let newRating = $(this).data("val");

				// Wenn auf das aktuelle Rating wieder geklickt wurde, soll rating auf 0 gesetzt werden
				if (newRating === $hRating.val()) {
					newRating = null;
				}

				$hRating.val(newRating);

				//Rating visuell darstellen
				showRating(newRating);
			});

			// Rating bei mouseover anzeigen
			$ratingDiv.delegate(".rating_icon", "mouseover", function () {
				const overRating = $(this).data("val");

				for (let i = settings.begin; i <= settings.end; i++) {
					const $cIcon = $ratingDiv.find(`.rating_${i}`);

					const curClass = i <= overRating ? "active" : "inactive";
					setClass(settings, $cIcon, curClass);

					if (i <= overRating) {
						setOpacity($cIcon, "low");
					}
				}
			});

			// Rating bei mouseout wieder ausblenden
			$ratingDiv.delegate(".rating_icon", "mouseout", () => {
				const rating = $hRating.val();

				for (let i = settings.begin; i <= settings.end; i++) {
					const $cIcon = $ratingDiv.find(`.rating_${i}`);

					const curClass = i <= rating ? "active" : "inactive";
					setClass(settings, $cIcon, curClass);
					setOpacity($cIcon, "high");
				}
			});
		}

		function showRating(rating) {
			// Rating runden
			const roundedRating = Math.round(rating);

			for (let i = settings.begin; i <= settings.end; i++) {
				const $cIcon = $ratingDiv.find(`.rating_${i}`);

				const curClass = i <= roundedRating ? "active" : "inactive";
				setClass(settings, $cIcon, curClass);
				setOpacity($cIcon, "high");
			}
		}
	};

	// Entfernt die Icon-Classes und setzt die übergebene Klasse
	function setClass(settings, $element, $status) {
		const remClass =
			$status === "active" ? settings.iconInactive : settings.iconActive;
		const addClass =
			$status === "active" ? settings.iconActive : settings.iconInactive;
		const remClassAttr =
			$status === "active"
				? settings.iconInactiveClassAttr
				: settings.iconActiveClassAttr;
		const addClassAttr =
			$status === "active"
				? settings.iconActiveClassAttr
				: settings.iconInactiveClassAttr;

		$element.removeClass(remClass);
		$element.removeClass(remClassAttr);

		$element.addClass(addClass);
		$element.addClass(addClassAttr);
	}

	// Setzt die Opacity anhand von Klassen aus der global.scss (TODO: In eigene SCSS?)
	function setOpacity($element, $status) {
		const remClass = $status === "low" ? "opacity-low" : "opacity-high";
		const addClass = $status === "low" ? "opacity-high" : "opacity-low";

		$element.removeClass(remClass);
		$element.addClass(addClass);
	}

	function generateRatingDiv(id, settings) {
		let r = `<div id='${id}' class='rating_div no-wrap'>`;

		for (let i = settings.begin; i <= settings.end; i++) {
			r += `<i
        class="${settings.iconInactiveClassAttr} rating_icon rating_${i} fa ${settings.iconInactive} ${settings.iconSize}"
        data-val="${i}"></i>`;
		}

		r += "</div>";

		return r;
	}
})(jQuery);
