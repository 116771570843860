import "../css/global.scss";

import select2 from "select2/dist/js/select2.full";

select2($);

import { select2ify } from "./utils/select2ify";

// Menüs aufklapbar machen

$(".navigation-box .box-head").on("click", function () {
	$(".box-content, .box-end", $(this).parent()).toggle("fast");
	return false;
});

select2ify($(".select2"));

$(".datepicker").datepicker({
	startView: "months",
	language: "de",
	autoclose: true,
	forceParse: false,
	format: "dd.mm.yyyy",
});

// Größe der Textareas deren Inhalt anpassen
$("textarea").elastic();
